define("ember-math-helpers/helpers/expm1", ["exports"], function (exports) {
  "use strict";

  exports.expm1 = expm1;
  var helper = Ember.Helper.helper;

  function expm1(params) {
    return Math.expm1(params[0]);
  }

  exports["default"] = helper(expm1);
});