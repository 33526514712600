define("ember-math-helpers/helpers/cbrt", ["exports"], function (exports) {
  "use strict";

  exports.cbrt = cbrt;
  var helper = Ember.Helper.helper;

  function cbrt(params) {
    return Math.cbrt(params[0]);
  }

  exports["default"] = helper(cbrt);
});