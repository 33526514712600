define("ember-math-helpers/helpers/atan", ["exports"], function (exports) {
  "use strict";

  exports.atan = atan;
  var helper = Ember.Helper.helper;

  function atan(params) {
    return Math.atan(params[0]);
  }

  exports["default"] = helper(atan);
});