define("ember-math-helpers/helpers/cosh", ["exports"], function (exports) {
  "use strict";

  exports.cosh = cosh;
  var helper = Ember.Helper.helper;

  function cosh(params) {
    return Math.cosh(params[0]);
  }

  exports["default"] = helper(cosh);
});