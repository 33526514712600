define("ember-math-helpers/helpers/floor", ["exports"], function (exports) {
  "use strict";

  exports.floor = floor;
  var helper = Ember.Helper.helper;

  function floor(params) {
    return Math.floor(params[0]);
  }

  exports["default"] = helper(floor);
});