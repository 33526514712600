define("ember-math-helpers/helpers/tanh", ["exports"], function (exports) {
  "use strict";

  exports.tanh = tanh;
  var helper = Ember.Helper.helper;

  function tanh(params) {
    return Math.tanh(params[0]);
  }

  exports["default"] = helper(tanh);
});