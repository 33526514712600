define("ember-math-helpers/helpers/log10", ["exports"], function (exports) {
  "use strict";

  exports.log10 = log10;
  var helper = Ember.Helper.helper;

  function log10(params) {
    return Math.log10(params[0]);
  }

  exports["default"] = helper(log10);
});