define("ember-math-helpers/helpers/fround", ["exports"], function (exports) {
  "use strict";

  exports.fround = fround;
  var helper = Ember.Helper.helper;

  function fround(params) {
    return Math.fround(params[0]);
  }

  exports["default"] = helper(fround);
});