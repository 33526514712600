define("ember-math-helpers/helpers/atanh", ["exports"], function (exports) {
  "use strict";

  exports.atanh = atanh;
  var helper = Ember.Helper.helper;

  function atanh(params) {
    return Math.atanh(params[0]);
  }

  exports["default"] = helper(atanh);
});