define("ember-math-helpers/helpers/mult", ["exports"], function (exports) {
  "use strict";

  exports.mult = mult;
  var helper = Ember.Helper.helper;

  function mult(params) {
    return params.reduce(function (a, b) {
      return Number(a) * Number(b);
    });
  }

  exports["default"] = helper(mult);
});