define("ember-math-helpers/helpers/asin", ["exports"], function (exports) {
  "use strict";

  exports.asin = asin;
  var helper = Ember.Helper.helper;

  function asin(params) {
    return Math.asin(params[0]);
  }

  exports["default"] = helper(asin);
});