define("ember-math-helpers/helpers/min", ["exports"], function (exports) {
  "use strict";

  exports.min = min;
  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var helper = Ember.Helper.helper;

  function min(params) {
    return Math.min.apply(Math, _toConsumableArray(params));
  }

  exports["default"] = helper(min);
});