define("ember-math-helpers/helpers/asinh", ["exports"], function (exports) {
  "use strict";

  exports.asinh = asinh;
  var helper = Ember.Helper.helper;

  function asinh(params) {
    return Math.asinh(params[0]);
  }

  exports["default"] = helper(asinh);
});