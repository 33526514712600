define("ember-math-helpers/helpers/log1p", ["exports"], function (exports) {
  "use strict";

  exports.log1p = log1p;
  var helper = Ember.Helper.helper;

  function log1p(params) {
    return Math.log1p(params[0]);
  }

  exports["default"] = helper(log1p);
});