define("ember-math-helpers/helpers/ceil", ["exports"], function (exports) {
  "use strict";

  exports.ceil = ceil;
  var helper = Ember.Helper.helper;

  function ceil(params) {
    return Math.ceil(params[0]);
  }

  exports["default"] = helper(ceil);
});