define("ember-math-helpers/helpers/trunc", ["exports"], function (exports) {
  "use strict";

  exports.trunc = trunc;
  var helper = Ember.Helper.helper;

  function trunc(params) {
    return Math.trunc(params[0]);
  }

  exports["default"] = helper(trunc);
});