define("ember-math-helpers/helpers/sin", ["exports"], function (exports) {
  "use strict";

  exports.sin = sin;
  var helper = Ember.Helper.helper;

  function sin(params) {
    return Math.sin(params[0]);
  }

  exports["default"] = helper(sin);
});