define("ember-math-helpers/helpers/abs", ["exports"], function (exports) {
  "use strict";

  exports.abs = abs;
  var helper = Ember.Helper.helper;

  function abs(params) {
    return Math.abs(params[0]);
  }

  exports["default"] = helper(abs);
});