define("ember-math-helpers/helpers/sqrt", ["exports"], function (exports) {
  "use strict";

  exports.sqrt = sqrt;
  var helper = Ember.Helper.helper;

  function sqrt(params) {
    return Math.sqrt(params[0]);
  }

  exports["default"] = helper(sqrt);
});