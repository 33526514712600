define("ember-math-helpers/helpers/add", ["exports"], function (exports) {
  "use strict";

  exports.add = add;
  var helper = Ember.Helper.helper;

  function add(params) {
    return params.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
  }

  exports["default"] = helper(add);
});