define("ember-math-helpers/helpers/sign", ["exports"], function (exports) {
  "use strict";

  exports.sign = sign;
  var helper = Ember.Helper.helper;

  function sign(params) {
    return Math.sign(params[0]);
  }

  exports["default"] = helper(sign);
});