define("ember-math-helpers/helpers/imul", ["exports"], function (exports) {
  "use strict";

  exports.imul = imul;
  var helper = Ember.Helper.helper;

  function imul(params) {
    return Math.imul(params[0], params[1]);
  }

  exports["default"] = helper(imul);
});