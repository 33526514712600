define("ember-math-helpers/helpers/log-e", ["exports"], function (exports) {
  "use strict";

  exports.logE = logE;
  var helper = Ember.Helper.helper;

  function logE(params) {
    return Math.log(params[0]);
  }

  exports["default"] = helper(logE);
});