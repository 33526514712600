define("ember-math-helpers/helpers/pow", ["exports"], function (exports) {
  "use strict";

  exports.pow = pow;
  var helper = Ember.Helper.helper;

  function pow(params) {
    return params.reduce(function (base, exponent) {
      return Math.pow(base, exponent);
    });
  }

  exports["default"] = helper(pow);
});