define("ember-math-helpers/helpers/round", ["exports"], function (exports) {
  "use strict";

  exports.round = round;
  var helper = Ember.Helper.helper;

  function round(number) {
    return Math.round(number[0]);
  }

  exports["default"] = helper(round);
});