define("ember-math-helpers/helpers/log2", ["exports"], function (exports) {
  "use strict";

  exports.log2 = log2;
  var helper = Ember.Helper.helper;

  function log2(params) {
    return Math.log2(params[0]);
  }

  exports["default"] = helper(log2);
});