define("ember-math-helpers/helpers/atan2", ["exports"], function (exports) {
  "use strict";

  exports.atan2 = atan2;
  var helper = Ember.Helper.helper;

  function atan2(params) {
    return Math.atan2(params[0], params[1]);
  }

  exports["default"] = helper(atan2);
});