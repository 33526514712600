define("ember-math-helpers/helpers/clz32", ["exports"], function (exports) {
  "use strict";

  exports.clz32 = clz32;
  var helper = Ember.Helper.helper;

  function clz32(params) {
    return Math.clz32(params[0]);
  }

  exports["default"] = helper(clz32);
});