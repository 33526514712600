define("ember-math-helpers/helpers/sub", ["exports"], function (exports) {
  "use strict";

  exports.sub = sub;
  var helper = Ember.Helper.helper;

  function sub(params) {
    return params.reduce(function (a, b) {
      return Number(a) - Number(b);
    });
  }

  exports["default"] = helper(sub);
});